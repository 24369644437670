body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling : touch !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rv-treemap__leaf__content {
  overflow: hidden;
  /*margin: 5px;*/
  /*padding: 2px 0;*/
  text-overflow: ellipsis;
  z-index: 1000;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  font-family: sans-serif;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.rv-treemap__leaf {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rv-treemap__leaf:hover {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
}

.tooltip-container {
  position: absolute;
  opacity: 0;
  background: #fff;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 0 8px 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1001;
}

.info-text {
  color: #000 !important;
  line-height: 14px;
  font-size: 14px;
}

